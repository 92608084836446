<template>
    <section class="content">
		<div class="card">
            <div class="card-body">
				              <div class="row">
          <div class="col-9">
            <div class="row form-horizontal">
              <div class="col-auto">
                <label class="control-label my-2 ml-2">
                  <h6>Join Date :</h6>
                </label>
              </div>
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      style="background-color: #fff"
                    >
                      <span><span class="fa fa-calendar"></span></span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
				
				<table class="table table-hover" ref="tableuser" id="tablshipper">
					<thead>
						<tr>
							<th>ID</th>
							<th>Nama</th>
							<th>Email</th>
							<th>No. HP</th>
							<th>Join</th>
							<th>Terakhir Login</th>
							<th>Alamat</th>
							<th>Type</th>
							<th>Shipment Amount</th>
							<th>Status</th>
							<th>CS/Sales</th>
							<th>Akses Kurir</th>
              <th>Tanda</th>
              <th>Rekening</th>
              <th>Afiliator</th>
							<th>Afilian</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
				<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<form role="form" @submit="submitForm">
								<div class="modal-header">
									<h5 class="modal-title">{{ formTitle }}</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
								</ul>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
							</p>
									<div class="row">
										<div class="col-md-6 form-group" v-if="false">
											<label class="control-label">Username</label>
											<input type="hidden" name="row_id" value="" />
											<input id="username" class="form-control" v-model="form.username" type="text" required="required" name="username" autocomplete="off" />
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Member Type</label>
											<select v-model="form.membership_type_id" class="form-control">
												<option v-for="role in memberType" v-bind:value="role.id" v-bind:key="role.id">{{ role.label }}</option>
											</select>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Estimasi jumlah kiriman harian</label>
											<select class="form-control" v-model="form.shipment_amount" required="true">
											<option value="" disabled selected>Pilihan</option>
											<option v-for="row in shipmentAmount" v-bind:key="row.num" v-bind:value="row.num">
												{{ row.label }}
											</option>
										</select>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Display Name</label>
											<input id="name" class="form-control" v-model="form.display_name" type="text" name="i_name" autocomplete="off" 
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"/>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Email</label>
											<input id="email" class="form-control" v-model="form.email" type="text" required="required" name="i_email" autocomplete="off" 
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==50 && event.shiftKey==true)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==true)
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"/>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">No HP</label>
											<input id="name" class="form-control" v-model="form.kontak_hp" type="number" required="required" name="i_hp" autocomplete="off" maxlength="15" 
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==32) || (event.keyCode==46))"/>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Bank</label>
											<select v-model="form.bank_id" class="form-control">
												<option v-for="role in bank" v-bind:value="role.id" v-bind:key="role.id">{{ role.label }}</option>
											</select>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">No Rekening</label>
											<input id="name" class="form-control" v-model="form.bank_rekening" type="number" name="i_norek" maxlength="24" autocomplete="off" 
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Atasnama</label>
											<input id="name" class="form-control" v-model="form.bank_atasnama" type="text" name="i_atasnama" autocomplete="off" 
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"/>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Alamat</label>
											<textarea id="name" class="form-control" v-model="form.alamat" type="text" name="i_alamat" autocomplete="off" 
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57  && event.shiftKey==true)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48  && event.shiftKey==true)
                      || (event.keyCode==190  && event.shiftKey==false) || (event.keyCode==188  && event.shiftKey==false)
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"></textarea>
										</div>
										<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label"> </label>
											<label class="checkbox control-label">
												<input value="1" checked="checked" type="checkbox" name="active" v-model="form.active"/> Active</label>
										</div>
    								<div class="col-md-6 col-xs-12 form-group">
    								<label class="control-label">CS / Sales</label>
    								<v-select :options="userCS" v-model="form.usercs_id" :reduce="opt => opt.id" />
    								</div>
    								<div class="col-sm-6 col-xs-12 form-group">
    								<label class="control-label">Akses Kurir</label>
    								<v-select :options="groups" v-model="form.courier_id" :reduce="opt => opt.courier_id" multiple />
    								</div>
    								<div class="col-md-6 form-group" v-if="userInfo.role_id != 3">
											<label class="control-label">Tanda</label>
											<select class="form-control" v-model="form.marking">
  											<option value=""></option>
  											<option value="blacklist">Blacklist</option>
  											<option value="suspect">Suspect</option>
  											<option value="trust">Trust</option>
  											<option value="suspect rekening" hidden> Auto suspect rekening</option>
  										</select>
                    </div>
                    <div class="col-12 form-group" v-if="userInfo.role_id != 3">
                      <label class="control-label">Info Tambahan</label>
                      <div class="row mb-1" v-for="(info, index) in additionals">
                        <div class="col">
                          <input class="form-control" v-model="additionals[index].name" type="text" autocomplete="off" placeholder="name" 
                          onkeydown="return (event.ctrlKey || event.altKey 
                        || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                        || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                        || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"/>
                        </div>
                        <div class="col">
                          <input class="form-control" v-model="additionals[index].value" type="number" autocomplete="off" placeholder="value" />
                        </div>
                        <div class="col-auto">
                          <button type="button" v-on:click="saveItem(index)" class="btn btn-primary mr-1"><i class="fa fa-check"></i></button>
                          <button type="button" v-on:click="deleteItem(index)" class="btn btn-secondary"><i class="fa fa-times"></i></button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <button type="button" class="btn btn-success btn-sm btn-block" v-on:click="addItem"><i class="fa fa-plus"></i></button>
                        </div>
                      </div>
    								</div>
									</div>
									
								</div>
								<div class="modal-footer">
									<button type="submit" class="btn btn-primary">Save changes</button>
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
  name: "Shipper",
  data() {
    return {
      errors: [],
      shipmentAmount: [{label: '1 - 5', num: 5}, {label: '6 - 30', num: 30}, {label: '31 - 100', num: 100}, {label: ' > 100', num: 1000}],
      method: "",
      roles: "",
      groups: [],
      memberType: [],
      userCS: [],
      bank: [],
      filter: {},
      dt1: moment().startOf("month"),
      dt2: moment(),
        data_type: "pickupTime",
      disabled: false,
      formTitle: "Tambah Shipper",
      form: {
        courier_id: [],
        name: "",
        username: "",
        display_name: "",
        email: "",
        membership_type_id: "",
        kontak_hp: "",
        bank_id: 0,
        bank_rekening: "",
        bank_atasnama: "",
        alamat: "",
        active: "",
        usercs_id: "",
        shipment_amount: "",
      },
      name: "",
      value: "",
      additionals: [{
        name: "",
        value: "",
      }],
      droppOffDisabled: false
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    vSelect,
  },
  watch:{
    $route (to, from){
      if(to.path === "/shipper/all")
      {
        sessionStorage.filterData='';
		    sessionStorage.filterStatus = '';
        this.filter = {};
        this.table.api().ajax.reload();
      }
    }
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

    if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;

    authFetch("/shipper/member_type")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.memberType = js.data;
      });

    authFetch("/shipper/bank")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.bank = js.data;
      });

      authFetch("/shipper/kurir")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
      this.loadCS();
  },
  methods: {
    loadCS: function () {
      this.roles = this.$route.meta.roles;
      this.userInfo = auth.user();
      this.filter.datatype = this.data_type;
      authFetch("/shipper/usercs")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.userCS = js.data;
        });
    },
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
      if (e.target.matches(".link-order")) {
        let route = this.$router.resolve({
          path: "/shipper/detail/" + e.target.dataset.id
        });
                window.open(route.href,"_blank");
        // return false;
      }
      if (e.target.matches(".link-chat")) {
              openChat(e.target.dataset.to);
              return false;
      }
    },
    dataType(event) {
      this.data_type = event.target.value;
      this.filter.datatype = this.data_type;
      // this.table.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.table.api().ajax.reload();
    },
    downloadXlsx: function (e) {
        this.downloading = true;
  		var table = $('#tablshipper').DataTable();
  		var length = table.page.info().recordsTotal;
  		
  		if (length > 20000) {
  			Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
  			this.downloading = false;
  			return false;
  		}
  		
  		var filter = JSON.parse(JSON.stringify(this.filter));
  			filter.q = $("input[name=q]").val();
  			filter.category = $("select[name=category]").val();

  		console.log(filter);
  		var data = Object.keys(filter)
  			.map(
  				(key) =>
  				encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
  			)
  			.join("&");
  		console.log(data)
  		this.loadingContent = true;
  		authFetch("/report/shipper/excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.downloading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "data.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var allData = { group: this.form };
      var urlSubmit = "/shipper";
      if (this.method == "PUT") urlSubmit = "/shipper/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: JSON.stringify(allData),
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
    addItem: function () {
      var addItem = {
        name: this.name,
        value: this.value,
      };
      this.additionals.push(addItem);
      this.name = "";
      this.value = "";
    },
    saveItem: function (val) {
      var data = Object.entries(this.additionals[val]).map(([key, val]) => `${key}=${val}`).join('&') + `&id=` + this.form.id;
      authFetch("/shipper/plus", {
        method: "PUT",
        body: data,
      })
      .then((res) => {
        return res.json();
      })
      .then((js) => {
        if (js.success) {
          Swal.fire("Berhasil", js.message, "success");
        } else {
          Swal.fire("Gagal", js.message, "error");
        }
      });
    },
    deleteItem: function (val) {
      authFetch("/shipper/plus", {
        method: "DELETE",
        body: `name=` + this.additionals[val].name + `&id=` + this.form.id,
      })
      .then((res) => {
        return res.json();
      })
      .then((js) => {
        if (js.success) {
          Swal.fire("Berhasil", js.message, "success");
          this.additionals.splice(val, 1);
        } else {
          Swal.fire("Gagal", js.message, "error");
        }
      });
    },
    loadDataUserDropOff() {
      let route = "/order/shipper_tag";
      try {
        authFetch(route).then(res => {
          res.json().then(json => {
            // console.log("ini ya popo ", json.info.drop_off);
            // console.log("og_status ", this.og_status);
            if (json.success == true){
              this.droppOffDisabled = json.info.drop_off;
            } else {
              this.droppOffDisabled = false;
            }
          });
        });
        
      } catch (error) {
        
      }
    },
  },

  mounted() {
    const e = this.$refs;
    var self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
					format: 'DD/MM/YYYY'
				},
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
      }
    );
    //console.log('roles', this.$route.params.roles);
    this.table = createTable(e.tableuser, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/shipper",
      frame: true,
      scrollX: true,
      processing: true,
      autoWidth:false,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
    
        paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
            //d = filter;
            //d.dev = filter.delivered;
            d.dt1 = self.dateStart;
            d.dt2 = self.dateEnd;
            d.datatype = self.data_type;
            var filter = JSON.parse(JSON.stringify(self.filter));
            for (const key in filter) {
                d[key] = filter[key] === false ? 0 : filter[key];
            }
        },
      columns: [
        { data: "shipper_code" },
        { data: "display_name",
        render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="` + row.user_id +'">'+
                    data +`</span>`);
            },
        },
        { data: "email" },
        { data: "kontak_hp",
        render: function (data, type, row, meta) {
            if (!data) {
                return '';
            } else {
                return (
                    '<a class="link link-chat text-info" style="cursor:pointer;" data-to="' + data + '">' + data + "</a>"
                );
            }
        }, },
        { data: "joined_on",
        render: function (data, type, row, meta) {
          return moment(row.created_on).format("DD/MM/YYYY HH:mm");
        }, },
        { data: "last_login",
        render: function (data, type, row, meta) {
          return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
        }, },
        { data: "alamat" },
        { data: "title" },
        { data: "shipment_amount",
        render: function (data, type, row, meta) {
            let amount = !data && data < 1 ? "" : data < 6 ? "1 - 5" : data < 31 ? "6 - 30" : data < 101 ? "31 - 100" : data >= 101 ? "> 100" : "-";
            return amount;
        }, },
        { data: "active",
        render: function (data, type, row, meta) {
          var status = null;
          if (data > 0) {
            status = '<span class="badge badge-success">Active</span>'
          } else {
            status = '<span class="badge badge-warning">Not Active</span>'
          } 
          if (row.ktp_verify == 1) {
            status += '<span class="badge badge-success">Verified KTP</span>'
          }
          if (row.drop_off == true) {
            status += '<span class="badge badge-success">Drop Off</span>'
          }
          if (row.is_valid_hp == "t") {
            status += '<span class="badge badge-success">Verified WA</span>'
          }
          return status;
        } },
        { data: "usercs" },
        { data: "kurir",
        sortable: false,
        render: function (data, type, row, meta) {
          let act = ``;
            for(var x in data)
            {
              var kurir = data[x];
              act += `
                <div style="display:inline-block;"><span class="badge badge-info">${kurir}</span></div>
              `;
            }
            return act;
          return '-';
        },},
        {
          data:'marking',
          render: function (data, type, row, meta) {
            var mark = data?data:'';
            if(row.banned=='t')mark+=' '+'<span class="badge badge-danger">banned</span>'
            return mark;
          }
        },
        {data: "rekening",
        sortable: false,
          render: function (data, type, row, meta) {
            // console.log(data['bank_rekening'] !== undefined);
            var rekening = '';
            if(data['bank_rekening'] !== undefined )rekening= `${data['bank_atasnama']}<br>${data['bank_rekening']}`;
            return rekening;
          }
        },
        {
          visible: false,
          data: "following",
          render: function (data, type, row, meta) {
            if (data) return data + " #" + row.following_id;
            else return ``;
          }
        },
        {
          visible: false,
          data: "followers",
          render: function (data, type, row, meta) {
            if (data) return data;
            else return 0;
          }
        },
      ],
      filterBy: [1, 2, 3, 13, 0],
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Shipper";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          //get kurir
          let data = evt.data.kurir; //nama kurir
          let id_kurir = evt.data.courier_id; //id kurir
          let len = data.length;
          let len2 = id_kurir.length;

          if (evt.data.kurir == []) {
            self.form.courier_id = "";
          } else {
            self.form.courier_id = [];
            if (len < 1 || len2 < 1) {
              self.form.courier_id;
            } else {
              for(let i = 0; i < len && i < len2; i++)
              {
                self.form.courier_id.push({
                      label: data[i],
                      courier_id: id_kurir[i],
                });
              }
            }
          }

          self.form.id = evt.data.id;
          self.form.name = evt.data.name;
          self.form.display_name = evt.data.display_name;
          self.form.username = evt.data.username;
          self.form.email = evt.data.email;
          self.form.membership_type_id = evt.data.membership_type_id;
          self.form.kontak_hp = evt.data.kontak_hp;
          self.form.bank_id = evt.data.bank_id;
          self.form.bank_rekening = evt.data.bank_rekening;
          self.form.bank_atasnama = evt.data.bank_atasnama;
          self.form.alamat = evt.data.alamat;
          self.form.usercs_id = evt.data.usercs_id;
          self.form.shipment_amount = evt.data.shipment_amount;
          self.form.marking = evt.data.marking;

          if (evt.data.active == 0 || !evt.data.active) {
            self.form.active = false;
          } else {
            self.form.active = true;
          }
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Shipper";
          authFetch("/shipper/category_meta/" + evt.data.id, {
            method: "GET",
          })
          .then((res) => {
            return res.json();
          })
          .then((js) => {
            self.additionals = js.data;
          });
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          self.form = evt.data;
          authFetch("/shipper/" + evt.data.id, {
            method: "DELETE",
            body: "id=" + evt.data.id,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              this.table.api().ajax.reload();
            });
        }
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>